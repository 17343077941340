import axios from 'axios'
import router from '../router'

export function request (config) {
  // 1 创建axios的实例
  const instance = axios.create({
    //测试地址：https://napi.guansj.cn/api
    //上线地址：https://newapi.guansj.com/api
    baseURL: 'https://newapi.guansj.com/api',
    timeout: 3000
  })

  // 2 axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(
    config => {
      // 判断是否存在token，如果存在的话，则每个http header都加上token

      // 获取 token        ///////////需优化
      let token = new Object()
      token = window.sessionStorage.getItem('authorization')

      if (typeof token === 'object') {
        config.headers.authorization = token
      } else {
        if (token.length == 0) {
          router.出({
            path: '/'
          })
        } else {
          config.headers.authorization = token
        }
      }
      return config
    },
    err => {
      console.log(err)
    }
  )

  // 2.2.响应拦截的作用
  instance.interceptors.response.use(
    res => {
      return res
    },
    err => {
      console.log(err)
    }
  )
  // 3.发送真正的网络请求
  return instance(config)
}
